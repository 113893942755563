<template>
  <intersection-div
    :class="`text-${mobile ? 'h6' : 'h4'} mb-0 d-flex`"
    style="font-family: 'ErbaumBook', sans-serif !important"
    rm="70"
    @gone="onTitleGone"
  >
    <span v-if="title" :class="{ 'text--disabled': loading }">
      {{ title }}
      <v-icon
        v-if="icon"
        :large="!small"
        right
        :class="{ 'mb-2': !small, 'text--disabled': loading }"
      >
        {{ mdiIcon }}
      </v-icon>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary lighten-3"
        style="top: -14px"
      />
    </span>
    <slot v-else />
  </intersection-div>
</template>

<script>
import { mapMutations } from 'vuex'
import IntersectionDiv from './IntersectionDiv.vue'
export default {
  components: { IntersectionDiv },
  name: 'PageTitle',
  data: () => ({
    tg: false,
  }),
  props: {
    title: {
      type: String,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mdiIcon() {
      return this.$route.meta?.icon
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
  },
  watch: {
    title() {
      this.onTitleGone(this.tg)
    },
  },
  methods: {
    ...mapMutations(['setTitle']),
    onTitleGone(state = false) {
      if (this.tg !== state) {
        this.tg = state
        this.setTitle(state ? this.title : null)
      }
    },
  },
  created() {
    this.onTitleGone(false)
  },
  beforeDestroy() {
    if (this.tg) this.setTitle('')
  },
}
</script>
