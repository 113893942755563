<template>
  <div
    v-intersect="{
      handler: onIntersect,
      options: {
        rootMargin: `-${rm}px`,
      },
    }"
  >
    <slot />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'IntersectionDiv',
  data: () => ({
    tm: null,
  }),
  props: { rm: { default: 100 }, dontUseStore: { default: false } },
  methods: {
    ...mapMutations(['setTitleGone']),
    onIntersect(entries /*, observer*/) {
      // Ловим событие ухода заголовка за экран, что бы изменить поведения прилипших строк.
      // More, unfo is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const isTitleGone = !entries[0].isIntersecting

      clearTimeout(this.tm)
      this.tm = setTimeout(() => {
        if (!this.dontUseStore) this.setTitleGone(isTitleGone)
        this.$emit('gone', !!isTitleGone)
        this.tm = null
      }, 100)
    },
  },
  destroyed() {
    // нужно ли сбросить флаг?
    if (this.tm) clearTimeout(this.tm)
    if (!this.dontUseStore) this.setTitleGone(false)
    this.$emit('gone', false)
  },
}
</script>
