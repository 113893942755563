<template>
  <div class="reference-page" id="reference-page">
    <PageTitle title="Справочники" icon />
    <v-container class="pt-0" fluid>
      <v-toolbar flat height="15">
        <template v-slot:extension>
          <v-tabs v-model="activeTab" center-active show-arrows>
            <v-tab
              v-for="tabs in refTabs"
              :key="tabs.name"
              :to="{ name: tabs.name }"
            >
              <v-icon v-if="tabs.meta.icon" left>{{ tabs.meta.icon }}</v-icon>
              {{ tabs.meta.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <keep-alive>
        <transition name="fade">
          <router-view />
        </transition>
      </keep-alive>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle.vue'
import { ROUTE_REFS } from '../route'

export default {
  name: 'ReferencesView',
  components: { PageTitle },
  data: () => ({
    activeTab: null,
  }),
  computed: {
    refMain() {
      const refMain = this.$router.options.routes.find(
        route => route.name === ROUTE_REFS
      )
      return refMain || {}
    },
    refTabs() {
      return this.refMain?.children || []
    },
  },
}
</script>
